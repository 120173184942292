<script>
    import { DateTime } from 'luxon';
    import RoundsNav from './RoundsNav.svelte';
    import BannedModal from './BannedModal.svelte';
    import SearchBox from '@/Components/SearchBox.svelte';
    import SuggestionsBox from '@/Components/SuggestionsBox.svelte';
    import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
    import { Menu, ArrowRight } from 'lucide-svelte';
    import { inertia } from '@inertiajs/svelte';

    export let suggestions;
    export let rounds;
    export let userRoundScore;
    export let baseScoreToBan;
    export let hasTwitterConnected;
    export let theme;
    export let sidebarSpot;
    export let suggestionSlider;

    let currentTimestamp = DateTime.now();

    const startTimestamp = (round) => {
        return DateTime.fromISO(round.start_timestamp);
    };

    const endTimestamp = (round) => {
        return DateTime.fromISO(round.end_timestamp);
    };

    const formatDateTime = (dateTime) => {
        return dateTime.toFormat('yyyy-MM-dd HH:mm:ss');
    };
</script>

<div class="container">
    <div class="row">
        {#if userRoundScore <= baseScoreToBan}
            <BannedModal />
        {/if}
        <div class="w-full p-0 md:w-3/5 lg:w-2/3">
            <div class="flex items-center justify-between px-3 pt-3">
                <div class="">
                    <h5 class="mb-2 font-bold">Rounds</h5>
                    <h6 class="mr-4 mt-2 flex text-muted">Repost eachother and grow together</h6>
                </div>
                <div>
                    <DropdownMenu.Root>
                        {#if hasTwitterConnected}
                            <DropdownMenu.Trigger>
                                <button
                                    type="button"
                                    class="m-0 block px-2 py-1 text-{theme} text-gray-800 hover:text-gray-600 md:hidden {userRoundScore <=
                                    baseScoreToBan
                                        ? 'cursor-not-allowed'
                                        : ''}"
                                    disabled="{userRoundScore <= baseScoreToBan}"
                                >
                                    <Menu />
                                </button>
                            </DropdownMenu.Trigger>
                        {/if}

                        <DropdownMenu.Content>
                            <DropdownMenu.Group>
                                <DropdownMenu.Item>
                                    <a href="{'/rounds'}" use:inertia class="p-2">{'Overview'}</a>
                                </DropdownMenu.Item>
                                <DropdownMenu.Item>
                                    <a href="{'/rounds/my'}" use:inertia class="p-2">{'Rounds participating'}</a>
                                </DropdownMenu.Item>
                                <DropdownMenu.Item>
                                    <a href="{'/rounds/history'}" use:inertia class="p-2">{'Rounds history'}</a>
                                </DropdownMenu.Item>
                            </DropdownMenu.Group>
                        </DropdownMenu.Content>
                    </DropdownMenu.Root>
                </div>
            </div>
            <hr />
            {#if hasTwitterConnected}
                <div
                    class="d-none d-md-block
                        {userRoundScore <= baseScoreToBan ? 'pointer-events-none blur-sm' : ''}"
                >
                    <RoundsNav />
                </div>
            {/if}
            <div class="m-3 {userRoundScore <= baseScoreToBan ? 'pointer-events-none blur-md' : ''}">
                {#each rounds as round}
                    <div class="mb-2 w-full place-content-center rounded-xl p-3 text-center shadow-lg">
                        <div class="mb-2 grid w-full place-content-center text-center">
                            <div
                                class="mb-2 inline-block bg-gradient-to-r
                                    from-bmn-500 via-bmn-500 to-bmn-800 bg-clip-text
                                    text-2xl font-extrabold text-transparent"
                            >
                                <span class="">{round.min_follower_count}+</span> Follower round
                            </div>
                        </div>
                        <div>
                            <dl
                                class="mb-0 grid grid-cols-1 divide-y divide-gray-200
                                    overflow-hidden rounded-lg md:divide-x md:divide-y-0 lg:grid-cols-2"
                            >
                                <div class="grid w-full place-content-center text-center md:py-2">
                                    <dt class="active-round flex-col items-center text-center font-normal">
                                        {#if currentTimestamp < startTimestamp(round) || currentTimestamp > endTimestamp(round)}
                                            <div
                                                id="start-time-{round.id}"
                                                class="flex justify-center text-center text-xs text-muted"
                                            >
                                                <div
                                                    class="h-[16px] w-[150px] animate-pulse rounded bg-slate-200"
                                                ></div>
                                            </div>
                                            <div
                                                id="countdown-{round.id}"
                                                class="flex items-center justify-center gap-1"
                                            >
                                                <div
                                                    class="mt-2 h-[56px] w-[200px] animate-pulse rounded bg-slate-200"
                                                ></div>
                                            </div>
                                        {/if}

                                        <!-- svelte-ignore empty-block -->

                                        {#if currentTimestamp < startTimestamp(round) || currentTimestamp > endTimestamp(round)}{:else}
                                            <div class="flex items-center justify-center gap-1">
                                                <div
                                                    class="inline-block bg-gradient-to-r from-bmn-600
                                                        to-bmn-500 bg-clip-text text-center
                                                        text-3xl font-bold text-transparent"
                                                >
                                                    RETWEET PHASE
                                                </div>
                                            </div>
                                            <div class="mt-1 flex justify-center text-center text-sm text-muted">
                                                <div>Ends: {formatDateTime(endTimestamp(round))}</div>
                                            </div>
                                        {/if}
                                    </dt>
                                </div>
                                <div class="grid content-center px-4 py-2">
                                    <div>
                                        {#if currentTimestamp < startTimestamp(round) || currentTimestamp > endTimestamp(round)}
                                            <div>
                                                <div class="text-center text-2xl font-bold">UPCOMING ROUND</div>
                                            </div>
                                            <div class="text-xs text-muted">
                                                Users signed up:
                                                {round.participants_number}/{round.max_participants_number}
                                            </div>
                                        {:else}
                                            <div>Total participants: {round.participants_number}</div>
                                            <div class="mt-3">
                                                <div class="text-s mb-2 text-left text-muted">
                                                    Retweets:
                                                    {round.retweet_count}/{round.participants_number - 1}
                                                </div>
                                                <div class="h-2.5 w-full rounded-full bg-gray-200 dark:bg-gray-700">
                                                    <div
                                                        class="h-2.5 rounded-full bg-gradient-to-r from-bmn-500
                                                        via-bmn-500 to-bmn-800 dark:bg-blue-500"
                                                        style="width:{(round.retweet_count /
                                                            Math.max(round.participants_number - 1, 1)) *
                                                            100}%"
                                                    ></div>
                                                </div>
                                            </div>
                                            <a
                                                href="{`/rounds/participation/${round.id}`}"
                                                class="flex items-center justify-end"
                                                use:inertia
                                            >
                                                <span class="mr-2">More details</span>
                                                <span>
                                                    <ArrowRight />
                                                </span>
                                            </a>
                                        {/if}
                                    </div>
                                </div>
                            </dl>
                        </div>
                    </div>
                {:else}
                    <div class="text-center py-4">
                        You are not currently participating in any round. Go to the <a href="{'/rounds'}" use:inertia
                            >overview page</a
                        > to check for available rounds.
                    </div>
                {/each}
            </div>
        </div>

        <div
            class="suggestions-wrapper
              hidden min-h-screen w-full border-l pb-5 pt-4 md:block md:w-2/5 lg:w-1/3"
        >
            <div class="feed-widgets">
                <div class="mb-4">
                    <SearchBox />
                </div>

                {#if !suggestionSlider}
                    <SuggestionsBox {suggestions} isMobile="{false}" />
                {/if}

                {#if sidebarSpot}
                    <div class="mt-4">
                        {sidebarSpot}
                    </div>
                {/if}
            </div>
        </div>
    </div>
</div>
